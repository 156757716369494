<template>
  <div>
    <p class="text-2xl mb-6">
      {{
        editing ? `Editer le group : ${group.name}` : "Créer un nouveau groupe"
      }}
    </p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="siteForm">
            <v-row>
              <v-col cols="12" sm="8">
                <div class="d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="group.name"
                    dense
                    label="Nom"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col cols="12" sm="8">
                <v-autocomplete
                  v-model="group.ingredients"
                  :items="items"
                  item-text="name"
                  item-value="name"
                  :search-input.sync="search"
                  v-on:keyup="debouncedGetData"
                  clearable
                  multiple
                  dense
                  hide-details
                  outlined
                  :menu-props="{ maxHeight: '400', zIndex: 13 }"
                  label="Ingrédients"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="saveGroup"
                >
                  Enregistrer le groupe</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  @click.native="goBackToListingPage"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import debounce from "lodash/debounce";
import { required } from "@core/utils/validation";
import router from "@/router";

export default {
  name: "CreateOrUpdate",
  data() {
    return {
      required,
      editing: false,
      group: {
        id: 0,
        name: "",
        ingredients: [],
      },
      search: "",
      items: [],
    };
  },
  created() {
    if ("id" in this.$route.params && this.$route.params.id) {
      this.initGroup(this.$route.params.id);
      this.editing = true;
    } else {
      this.editing = false;
    }
    this.getData();
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const { data } = await axios.get("/regie/marmiton/ingredients", {
        params: { search_text: this.search },
      });

      this.items = this.group.ingredients.concat(data);
    },
    async initGroup(id) {
      const { status, data } = await axios.get(
        `/regie/marmiton/ingredient-group/${id}`
      );
      if (status === 200) {
        this.group = {
          id: data.id,
          name: data.name,
          ingredients: data.ingredients.split(",").map((name) => ({ name })),
        };
      }
    },
    async saveGroup() {
      const isFormValid = this.$refs.siteForm.validate();
      if (!isFormValid) {
        return;
      }

      const payload = {
        name: this.group.name,
        ingredients: this.group.ingredients.join(","),
      };

      if (this.editing) {
        const { status } = await axios.put(
          `/regie/marmiton/ingredient-group/${this.group.id}`,
          payload
        );
        if (status === 200) {
          await this.goBackToListingPage();
        }
      } else {
        const { status } = await axios.post(
          "/regie/marmiton/ingredient-group",
          payload
        );
        if (status === 200) {
          await this.goBackToListingPage();
        }
      }
    },
    async goBackToListingPage() {
      await router.push({ name: "admin-marmiton-list-ingredient-groups" });
    },
  },
};
</script>

<style scoped></style>
